#root {
  background: url(assets/icon.png) right top no-repeat, url(assets/icon2.png) left center no-repeat;
  min-width: 1142px;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  font-family: "Raleway";
}

button, input, textarea {
  font-weight: 600;
  cursor: pointer;
  height: 48px !important;
  font-size: 18px;
  background-color: transparent;
  border: none;
  outline: none;
}

h4, h5, p, ul, li {
  font-weight: 500;
}

a, h5 {
  color: #85868a;
}

a {
  font-weight: 600;
}

.input_email {
  color: #57585c;
  cursor: text;
}

.input_email::placeholder {
  opacity: 0.5;
}

h1, h2, h3, h4 {
  color: #57585c;
  font-weight: 700;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
}

.ant-tabs-tab {
  margin-right: 24px;
  color: #3a3c42;
}

.ant-tabs-tab, .ant-tabs-tab.ant-tabs-tab-active {
  font-size: 16px;
  font-weight: 550;
  height: 33px !important;
}

a.login-link {
  margin: 0 24px;
  color: #3a3c42;
  font-size: 16px;
}

a.signup-link {
  font-size: 16px;
}

a.signup-link:hover {
  color: white;
}

.ant-tabs-top>.ant-tabs-nav::before {
  display: none;
}

.button {
  border: 1px solid #0079ee;
  color: #0079ee;
  background-color: transparent;
  padding: 10px 24px;
  outline: none;
  border-radius: 4px;
  font-weight: 600;
  font-style: normal;
}

p, ul, li {
  font-size: 16px;
  line-height: 30px;
}

.button_primary {
  background-color: #0079ee;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
}

.wrapper {
  max-width: 1142px;
  margin: 0 auto;
}

.slider__controll {
  position: absolute;
  top: 360px;
  right: -96px;
  background-image: url(./assets/Arrow\ 2.svg);
  background-position: center;
  background-color: transparent;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  width: 100px;
  ;
}

.slider__controll--left {
  left: -96px;
  transform: rotate(180deg);
}

.slider_dots li, .slider_dots li button, .slider_dots li {
  border-radius: 50% !important;
  width: 16px !important;
  height: 16px !important;
  background: #c3cfd8;
}

.ant-carousel .slick-dots-bottom {
  bottom: -48px;
}

.slider_dots li.slick-active button {
  background-color: #0079ee !important;
}

.slider img {
  margin: 0 auto;
  width: auto;
  height: 320px;
}

.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal_wrapper .close_button {
  position: absolute;
  top: 0;
  right: 15px;
}

.modal .success_message p {
  color: #3eb98d;
  margin: 0;
}

.modal_wrapper .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  min-width: 800px;
}

.modal .form_fields {
  display: flex;
  margin-bottom: 40px;
}

.modal .form_fields div {
  margin-right: 15px
}

.modal_wrapper .modal h5 {
  color: #85868a;
  margin-bottom: 50px;
}

.modal input::placeholder, .modal textarea::placeholder {
  border-radius: 4px;
  opacity: 0.5;
}

.modal input:not(:last-of-type) {
  margin-bottom: 12px;
}

.modal textarea {
  width: 346px;
  height: 168px !important;
}

.modal input, .modal textarea {
  padding: 15px;
  border: 1px solid #57585c;
  border-radius: 4px;
  width: 100%;
  resize: none;
  cursor: text;
  color: #57585C;
}

.input_email {
  box-sizing: border-box;
  border-radius: 4px;
  width: 220px;
  padding: 15px;
  background-color: #ffffff;
}

.dflex {
  display: flex;
}

.title {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 96px;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  transition: all 0.1s ease-in;
}

.header_bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.5;
}

.header>.wrapper {
  display: flex;
  padding: 24px;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.1s ease-in;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav .link-group {
  display: flex;
  align-items: center;
}

.nav>a {
  margin-right: 24px;
}

.nav button {
  margin-left: auto;
}

.nav button.ant-tabs-tab {
  color: #3a3c42;
}

.nav a>img {
  margin-right: 24px;
}

.nav .ant-tabs-nav {
  margin: 0;
}

.home {
  padding-top: 172px;
}

.home .info {
  max-width: 700px;
}

.home .info h3 {
  margin: 0;
  color: #3a3c42;
  font-weight: 800;
  font-size: 42px;
  line-height: 52px;
}

.home .info h3.special-title {
  color: #0079ee;
}

.home .info h2 {
  font-size: 60px;
}

.home .info p {
  font-size: 18px;
  color: #3a3c42;
  font-weight: 500;
  margin-top: 24px;
}

.home form {
  display: flex;
}

.home form input {
  margin-right: 15px;
}

.home button {
  margin-right: 15px;
}

.home .main-image {
  margin-top: 72px;
  position: relative;
}

.home .main-image img {
  width: 100%;
}

.home .main-image video {
  width: 75%;
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  height: 85%;
}

.card {
  max-width: 480px;
  background: #ffffff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 48px 48px 0 48px;
}

.modal.card {
  padding: 48px;
}

.card .plug {
  width: 100%;
  height: auto;
}

.card .plug img {
  width: 100%;
  height: auto;
}

.home .card h4 {
  color: #57585c;
  font-weight: 600;
  margin-bottom: 24px;
}

.card h5 {
  color: #3eb98d;
}

.card .email_form button {
  background: #3eb98d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 0 24px;
  color: white;
  border: 1px solid #3eb98d;
  cursor: pointer;
  margin: 0;
}

.accounted_list {
  list-style-type: none;
  padding: 24px;
}

.accounted_list li {
  margin-top: 12px;
  position: relative;
}

.accounted_list li::before {
  content: " ";
  position: absolute;
  top: 3px;
  left: -28px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #167cea;
}

.accounted_list li:last-of-type::before {
  background-color: #c3cfd8;
}

.graph {
  display: flex;
}

.graph .left_img {
  margin-left: 50px;
}

.graph .right_img {
  margin-right: 50px;
}

.graph img {
  width: 585px;
  height: 325px;
}

.graph .info p {
  color: #636672;
  font-size: 18px;
  font-weight: 600;
}

.why-decision {
  margin-top: 50px;
}

.problem_solution {
  max-width: 900px;
  margin: 100px auto 0;
}

.problem_solution h4 {
  color: #57585c;
  font-weight: 800;
}

.problem_solution h5 {
  color: #636672;
}

.problem_solution h3 {
  color: #3a3c42;
  font-weight: 800;
}

.impact .info {
  max-width: 25vw;
  margin-top: 45px;
}

.decision-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 75px;
  padding: 120px 0 60px;
  max-width: 900px;
  margin: 0 auto;
}

.decision-list .decision-item {
  max-width: 400px;
}

.decision-list .decision-item h5 {
  font-weight: 800;
  font-size: 24px;
  color: #3a3c42;
  line-height: 28px;
}

.decision-list .decision-item p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  color: #636672;
}

.ourview {
  padding: 120px 0 80px;
  background: linear-gradient( 180deg, rgba(0, 121, 238, 0.15) 0%, rgba(0, 121, 238, 0.05) 100%);
  text-align: center;
  margin-top: 48px;
}

.ourview .title {
  margin-bottom: 48px;
}

.ourview .title h5 {
  color: #636672;
  font-weight: 600;
}

.ourview .title h3 {
  color: #3A3C42;
  font-weight: 800;
}

.ourview p {
  max-width: 600px;
  margin: 48px auto;
  color: #636672;
}

.ourview .slider {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
}

.footer {
  display: flex;
  padding: 48px;
  gap: 48px;
}

.footer__card {
  background-color: rgba(58, 60, 66, 0.1);
  border-radius: 20px;
  padding: 44px 41px;
  padding-right: 177px;
  color: #ffffff;
  margin-left: auto;
  min-width: 300px;
}

.footer__card h4 {
  color: #3A3C42;
}

.footer__card p {
  color: #636672;
}

.footer__card .button {
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;
}

.footer__nav {
  flex: 1;
  justify-content: space-between;
  min-width: 300px;
}

.footer__nav ul {
  margin-top: 40px;
  list-style: none;
  padding: 0;
}

.footer__nav li {
  padding: 5px;
}

.footer__nav li a {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #636672;
}

.ctas {
  margin-top: 48px;
}

/* Video Custom Style */
.demo-video {
  text-align: center;
  margin-bottom: 65px;
  padding-top: 120px;
}

.demo-video .video-wrapper {
  position: relative;
  transform: translateY(0px);
  max-width: 600px;
  margin: 0 auto;
  border-radius: 24px;
  border: 21px solid #0079EE;
}

.demo-video .video-wrapper img.video-image {
  width: 100%;
}

.demo-video .video-wrapper .play-btn-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 115px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.demo-video .video-wrapper .play-btn-wrapper img {
  cursor: pointer;
  width: 100%;
}

.video-wrapper p {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  color: white;
  width: 100%;
}

@media screen and (max-width: 990px) {
  #root {
    min-width: auto;
    padding-top: 100px;
  }
  .addition-part div.login-part {
    margin: 16px 0;
  }
  .addition-part div.signup-part {
    margin: 34px 0 24px 24px;
  }
  .addition-part button {
    margin: 12px 0 15px 24px;
  }
  .wrapper {
    max-width: 100vw;
    margin: 0 auto;
  }
  .header>.wrapper {
    padding: 12px;
  }
  .home {
    flex-direction: column;
    align-items: center;
    padding: 0 12px;
  }
  .home .info {
    padding: 12px;
  }
  .home .info h3 {
    font-size: 24px;
    line-height: 45px;
  }
  .card {
    width: 100%;
    padding: 24px 24px 0 24px;
  }
  .graph {
    flex-direction: column;
    align-items: center;
  }
  .dflex {
    flex-direction: column;
    align-items: center;
  }
  .footer {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
    padding: 48px 12px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 3px;
  }
  .ourview {
    margin-top: 0;
  }
  .home {
    gap: 48px;
  }
  .home form input {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .home .info {
    margin-right: 0;
  }
  .home .info h2 {
    font-size: 36px;
  }
  .home form {
    flex-wrap: wrap;
  }
  .home button:first-child {
    margin-bottom: 24px;
  }
  .home .main-image {
    margin-top: 0;
  }
  .card .plug {
    width: 100%;
  }
  .title {
    margin-bottom: 48px;
    padding: 0 12px;
  }
  .why-decision {
    padding: 24px;
    margin-top: 0;
  }
  .demo-video {
    padding-top: 80px;
    margin-bottom: 80px;
  }
  .problem_solution {
    padding: 0 24px;
  }
  .problem_solution h4 {
    width: 100%;
  }
  .graph:last-of-type {
    flex-direction: column-reverse;
  }
  .graph img {
    width: 100%;
    height: auto;
    padding: 12px;
    margin-top: 50px;
  }
  .graph .right_img {
    margin-right: 0;
  }
  .graph .left_img {
    margin-left: 0
  }
  .impact {
    margin-bottom: 80px;
  }
  .impact .info {
    width: 100%;
    max-width: 100%;
    padding: 24px;
  }
  .accounted_list {
    list-style-type: none;
    padding: 12px 24px 0;
    margin-bottom: 0;
  }
  .dflex img {
    width: 100%;
    height: auto;
  }
  .dflex .wrapper {
    margin-bottom: 48px;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -48px;
  }
  .ant-carousel p {
    padding: 0 24px;
  }
  .slick-track {
    margin-bottom: 24px;
  }
  .slider__controll {
    right: 12px;
    top: auto;
    bottom: -48px;
  }
  .slider__controll--left {
    left: 12px;
  }
  .slider img {
    width: 300px;
    height: auto;
  }
  .footer__card {
    padding: 24px;
  }
  .footer__card h4 {
    width: 100%;
  }
  .footer__card p {
    font-size: 15px;
    font-weight: 400;
  }
  .footer__card .button {
    margin-left: 0;
    margin-top: 12px;
  }
  .footer__nav {
    text-align: center;
  }
  .footer a {
    font-weight: 500;
  }
  .header .wrapper {
    margin: 0;
    display: block;
  }
  .header .nav {
    justify-content: space-between;
  }
  .ant-tabs {
    margin-top: 48px;
  }
  .ant-tabs-right>.ant-tabs-content-holder {
    display: none;
  }
  .modal_wrapper .modal {
    top: 0;
    left: 0;
    min-width: 100vw;
    transform: none;
    border-radius: 0;
    padding: 24px;
  }
  .modal_wrapper .modal h5 {
    min-width: 80vw;
    letter-spacing: 1px;
  }
  .modal_wrapper .form_fields {
    flex-direction: column;
    align-items: center;
  }
  .modal .form_fields div {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .ant-tabs {
    width: 100%;
  }
  .header_bg {
    display: block;
  }
  .decision-list {
    padding: 80px 0 60px;
    grid-template-columns: repeat(1, 1fr);
  }
  .play-btn-wrapper {
    width: 75px !important;
    height: 75px !important;
    top: 60% !important;
  }
  .video-wrapper p {
    font-size: 24px;
  }
}
